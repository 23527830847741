import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Masthead, {
  MastheadMenuButton,
  MastheadTitle,
} from '@amzn/meridian/masthead';
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import Text from '@amzn/meridian/text';
import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import { useHistory } from 'react-router-dom';
import { ROUTES_PAGES_MAPPING } from '../../constants/routesPageMapping';

async function signOut() {
  try {
      await Auth.signOut();
  } catch (error) {
  }
}

const redirectToWiki = () => {
  window.open('https://w.amazon.com/bin/view/ATI-Team/Products/ScheduleFinder/');
}

const AppMasthead = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  return (
    <Masthead>
      <MastheadMenuButton onClick={() => setOpen(!open)} />
      <SideMenu open={open} onClose={() => setOpen(false)} type="overlay">
        {ROUTES_PAGES_MAPPING.map((pagePair) => (
          <SideMenuLink
            key={pagePair}
            type="tertiary"
            onClick={() => {
              history.push('/' + pagePair[0]);
              setOpen(false);
            }}
          >
            {pagePair[1]}
          </SideMenuLink>
        ))}
      </SideMenu>

      <MastheadTitle>
        <Text type="h200">Internal Schedule Finder</Text>
      </MastheadTitle>
      <Button type="icon" onClick={redirectToWiki}>
        <Icon tokens={helpTokens} />
      </Button>
      <Button onClick={() => signOut()}>Sign Out</Button> 
    </Masthead>
  );
};

export default AppMasthead;
