import { getEnvConfig } from '../helpers/configHelper';
import { Auth } from 'aws-amplify';

/**
 * Override the beta configurations when testing locally with local Cognito stack
 */
const apiConfig = {
  dev: {
    endpoints: [
      {
        name: 'SMS',
        endpoint:
          'https://yx7yverfkd.execute-api.us-east-1.amazonaws.com/Beta/sfv2/admin',
        headers: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
  test: {
    endpoints: [
      {
        name: 'SMS',
        endpoint:
          'https://yx7yverfkd.execute-api.us-east-1.amazonaws.com/Beta/sfv2/admin',
        headers: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
  beta: {
    endpoints: [
      {
        name: 'SMS',
        endpoint:
          'https://61b9r5cui4.execute-api.us-east-1.amazonaws.com/Beta/sfv2/admin',
        headers: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
  prod: {
    endpoints: [
      {
        name: 'SMS',
        endpoint:
          'https://b7r1twrub8.execute-api.us-east-1.amazonaws.com/Prod/sfv2/admin',
        headers: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export const ApiConfig = getEnvConfig(apiConfig);
