import { ADD_ADMIN } from '../actions/types';
const admin = (state = defaultAdminState, action) => {
  switch (action.type) {
    case ADD_ADMIN:
      return action.payload;
    default:
      return state;
  }
};

const defaultAdminState = {
  email: undefined,
  login: 'placeholder', //TODO: Should change it back to undefined when midway auth is accomplished.
  ldap: [],
};

export default admin;
