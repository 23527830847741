import {
  SCHEDULES_ARE_LOADING,
  SELECTED_SCHEDULES_ARE_LOADING,
  CANDIDATES_ARE_LOADING,
  PENDING_CANDIDATES_ARE_LOADING,
  ADMIN_IS_LOADING,
} from '../actions/types';

const loading = (state = defaultLoadingState, action) => {
  switch (action.type) {
    case ADMIN_IS_LOADING:
      return {
        ...state,
        adminLoading: action.payload,
      };
    case SCHEDULES_ARE_LOADING:
      return {
        ...state,
        schedulesLoading: action.payload,
      };
    case SELECTED_SCHEDULES_ARE_LOADING:
      return {
        ...state,
        selectedSchedulesLoading: action.payload,
      };
    case CANDIDATES_ARE_LOADING:
      return {
        ...state,
        candidatesLoading: action.payload,
      };
    case PENDING_CANDIDATES_ARE_LOADING:
      return {
        ...state,
        pendingCandidatesLoading: action.payload,
      };
    default:
      return state;
  }
};

export const defaultLoadingState = {
  adminLoading: false,
  schedulesLoading: false,
  selectedSchedulesLoading: false,
  candidatesLoading: false,
  pendingCandidatesLoading: false,
};

export default loading;
