import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Authenticated from './Authenticated';

import App from './App';

function Root({ store }) {
  return (
    <Authenticated>
      <Provider store={store}>
        <div>
          <Router>
            <Route path="/" component={App} />
          </Router>
        </div>
      </Provider>
    </Authenticated>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;