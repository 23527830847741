import { ADD_SELECTED_SCHEDULES } from '../actions/types';
import { get } from 'lodash';
import { WEEKDAYS } from '../../constants/days';

const selectedSchedules = (state = defaultSelectedSchedulesState, action) => {
  switch (action.type) {
    case ADD_SELECTED_SCHEDULES:
      return action.payload
        .filter((value) => value.schedule)
        .map((value) => {
          const schedule = value.schedule;
          const candidate = value.candidate;
          return {
            candidateEmail: candidate.candidateEmail,
            candidateId: candidate.candidateId,
            classId: candidate.classId,
            dayOne: candidate.dayOne,
            firstName: candidate.firstName,
            language: candidate.language,
            lastName: candidate.lastName,
            ouId: candidate.ouId,
            site: candidate.site,
            available: schedule.available,
            durationInHours: schedule.durationInHours,
            startTime: schedule.startTime,
            workGroup: schedule.workGroup,
            ...WEEKDAYS.reduce(
              (acc, key) => ({
                ...acc,
                [key]: schedule.workingDays[key] ? 'Work' : 'Off',
              }),
              {}
            ),
            lunchBreak1: get(schedule, 'lunchBreaks.0.startTime'),
            lunchBreak2: get(schedule, 'lunchBreaks.1.startTime'),
            shortBreak1: get(schedule, 'shortBreaks.0.startTime'),
            shortBreak2: get(schedule, 'shortBreaks.1.startTime'),
          };
        });
    default:
      return state;
  }
};

const defaultSelectedSchedulesState = [];

export default selectedSchedules;
