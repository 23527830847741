import {
  SHOW_BANNER_MESSAGE,
  DISMISS_BANNER_MESSAGE,
  CLEAR_ALL_BANNER_MESSAGES,
} from './types';
import generateUniqueId from '../../helpers/generateUniqueId';

export const SUCCESS_TYPE = 'success';
export const ERROR_TYPE = 'error';
export const WARNING_TYPE = 'warning';

export function showSuccessBanner(messageText) {
  return showBannerMessage({
    header: messageText,
    type: SUCCESS_TYPE,
  });
}

export function showErrorBanner(messageText) {
  return showBannerMessage({
    header: messageText,
    type: ERROR_TYPE,
  });
}

export function showWarningBanner(messageText) {
  return showBannerMessage({
    header: messageText,
    type: WARNING_TYPE,
  });
}

function showBannerMessage(messageInfo) {
  return {
    type: SHOW_BANNER_MESSAGE,
    payload: {
      id: generateUniqueId(),
      ...messageInfo,
    },
  };
}

export function clearAllBannerMessages() {
  return {
    type: CLEAR_ALL_BANNER_MESSAGES,
  };
}

export function dismissBannerMessage(messageId) {
  return {
    type: DISMISS_BANNER_MESSAGE,
    payload: messageId,
  };
}
