// SCHEDULES
export const ADD_SCHEDULES = 'ADD_SCHEDULES';
export const UPDATE_SCHEDULES = 'UPDATE_SCHEDULES';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';

// SELECTED SCHEDULES
export const ADD_SELECTED_SCHEDULES = 'ADD_SELECTED_SCHEDULES';

// CANDIDATES
export const UPDATE_CANDIDATES = 'UPDATE_CANDIDATES';
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';
export const ADD_CANDIDATES = 'ADD_CANDIDATES';

// PENDING CANDIDATES
export const ADD_PENDING_CANDIDATES = 'ADD_PENDING_CANDIDATES';

// LOADING
export const SCHEDULES_ARE_LOADING = 'SCHEDULES_ARE_LOADING';
export const SELECTED_SCHEDULES_ARE_LOADING = 'SELECTED_SCHEDULES_ARE_LOADING';
export const CANDIDATES_ARE_LOADING = 'CANDIDATES_ARE_LOADING';
export const PENDING_CANDIDATES_ARE_LOADING = 'PENDING_CANDIDATES_ARE_LOADING';
export const ADMIN_IS_LOADING = 'ADMIN_IS_LOADING';

// BANNER MESSAGES
export const SHOW_BANNER_MESSAGE = 'SHOW_BANNER_MESSAGE';
export const DISMISS_BANNER_MESSAGE = 'DISMISS_BANNER_MESSAGE';
export const CLEAR_ALL_BANNER_MESSAGES = 'CLEAR_ALL_BANNER_MESSAGES';

//ADMIN
export const ADD_ADMIN = 'ADD_ADMIN';
