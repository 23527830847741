import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Event, withARTWebMetrics } from '@art/react-metrics'; //withARTWebMetrics is a HOC
import Box from '@amzn/meridian/box';
import { useSelector } from 'react-redux';
import {
  ACCESS_DENIED_PATH,
  ROOT_PATH,
  SCHEDULE_MANAGER_PATH,
  SELECTED_SCHEDULES_PATH,
  PENDING_CANDIDATES_PATH,
  CANDIDATE_MANAGER_PATH,
} from '../../constants/routePaths';
import AppMasthead from '../components/AppMasthead';
import BannerMessages from '../components/BannerMessages';
import MyLoadable from '../components/LoadableComponent';

import '../styles/navigation.css';
import '../styles/app.scss';
import '@amzn/awsui-components-react/index.css';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const event = new Event('AppComponent', 'atf');
const AccessDeniedPage = MyLoadable({
  loader: () => import('./AccessDeniedPage'),
});
const authorizedRoutes = [
  {
    path: ROOT_PATH,
    component: MyLoadable({
      loader: () => import('./HomePage'),
    }),
  },
  {
    path: SCHEDULE_MANAGER_PATH,
    component: MyLoadable({
      loader: () => import('./ScheduleManagerPage'),
    }),
  },
  {
    path: PENDING_CANDIDATES_PATH,
    component: MyLoadable({
      loader: () => import('./PendingCandidatesPage'),
    }),
  },
  {
    path: SELECTED_SCHEDULES_PATH,
    component: MyLoadable({
      loader: () => import('./SelectedSchedulesPage'),
    }),
  },
  {
    path: CANDIDATE_MANAGER_PATH,
    component: MyLoadable({
      loader: () => import('./CandidateManagerPage'),
    }),
  },
];
const App = ({ record }) => {
  useEffect(() => {
    record(event);
  }, []);
  const admin = useSelector((state) => state.admin);
  return (
    <div className="appLayout awsui">
      <AppMasthead />
      <BannerMessages />
      <Box className="appContainer" backgroundColor="primary">
        <Switch>
          {admin.login ? (
            <>
              <Route path={ACCESS_DENIED_PATH}>
                <Redirect to={ROOT_PATH} />
              </Route>
              {authorizedRoutes.map((route, i) => {
                return (
                  <Route
                    exact
                    path={route.path}
                    key={route.path + i}
                    component={route.component}
                  />
                );
              })}
            </>
          ) : (
            <>
              <Route path={ROOT_PATH}>
                <Redirect to={ACCESS_DENIED_PATH} />
              </Route>
              <Route path={ACCESS_DENIED_PATH} component={AccessDeniedPage} />
            </>
          )}
        </Switch>
      </Box>
    </div>
  );
};

App.propTypes = {
  record: PropTypes.func.isRequired,
};

export default withARTWebMetrics(App);
