const getEnv = () => {
  if (window.location.href.includes('d1hx8qyj39c7h2.cloudfront.net')) {
    return 'test';
  } else if (
    window.location.href.includes('internal.beta.schedule-finder.cs.amazon.dev')
  ) {
    return 'beta';
  } else if (
    window.location.href.includes('internal.na.schedule-finder.cs.amazon.dev')
  ) {
    return 'prod';
  } else {
    return 'dev';
  }
};
export const getEnvConfig = (config) => config[getEnv()];
