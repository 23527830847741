// WEEKDAYS provide the order of 7 weekdays. If you want to use weekday with capital to be the header, use WEEKDAYS below as the order, and map to CAPITAL_WEEKDAYS_MAPPING
export const WEEKDAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const CAPITAL_WEEKDAYS_MAPPING = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
};
