import React from 'react';
import { Flashbar } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { dismissBannerMessage } from '../actions/bannerMessages';

const BannerMessages = ({}) => {
  const bannerMessages = useSelector((state) => state.bannerMessages);
  const dispatch = useDispatch();

  const items = bannerMessages.map((message) => {
    return {
      header: message.header,
      type: message.type,
      dismiss: () => {
        dispatch(dismissBannerMessage(message.id));
      },
      dismissible: true,
    };
  });

  return <Flashbar items={items} />;
};

export default BannerMessages;
