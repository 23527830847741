import {
  SHOW_BANNER_MESSAGE,
  DISMISS_BANNER_MESSAGE,
  CLEAR_ALL_BANNER_MESSAGES,
} from '../actions/types';

const bannerMessages = (state = defaultBannerMessagesState, action) => {
  switch (action.type) {
    case SHOW_BANNER_MESSAGE:
      return state.concat(action.payload);
    case DISMISS_BANNER_MESSAGE:
      return state.filter((message) => message.id !== action.payload);
    case CLEAR_ALL_BANNER_MESSAGES:
      return [];
    default:
      return state;
  }
};

const defaultBannerMessagesState = [];

export default bannerMessages;
