import { ADD_SCHEDULES, UPDATE_SCHEDULES } from '../actions/types';
import { WEEKDAYS } from '../../constants/days';

const schedules = (state = defaultSchedulesState, action) => {
  switch (action.type) {
    case ADD_SCHEDULES:
      return action.payload.map(weekdayFormatting);
    case UPDATE_SCHEDULES:
      return [...action.payload.map(weekdayFormatting), ...state];
    default:
      return state;
  }
};

const weekdayFormatting = (schedule) => ({
  ...schedule,
  ...WEEKDAYS.reduce(
    (acc, key) => ({
      ...acc,
      [key]: schedule.workingDays[key] ? 'Work' : 'Off',
    }),
    {}
  ),
});

const defaultSchedulesState = [];

export default schedules;
