import { getEnvConfig } from '../helpers/configHelper';
/**
 * Override the beta configurations when testing locally with local Cognito stack
 */
const userPoolConfig = {
  dev: {
    region: 'us-east-1',
    poolId: 'us-east-1_MDxtyC2sb',
    clientId: '1ik8cpmo96oolrbdhlm3h8c5g3',
    oauth: {
      domain: 'schedule-manager-dev.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: '', // Replace with your dev-desktop url
      redirectSignOut: '', // Replace with your dev-desktop url
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  test: {
    region: 'us-east-1',
    poolId: 'us-east-1_MDxtyC2sb',
    clientId: '1ik8cpmo96oolrbdhlm3h8c5g3',
    oauth: {
      domain: 'schedule-manager-dev.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://d1hx8qyj39c7h2.cloudfront.net',
      redirectSignOut: 'https://d1hx8qyj39c7h2.cloudfront.net',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  beta: {
    region: 'us-east-1',
    poolId: 'us-east-1_YyiiZsbph',
    clientId: '65kpf023apr3aiiik2prrn3iqb',
    oauth: {
      domain: 'schedule-manager-beta.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://internal.beta.schedule-finder.cs.amazon.dev',
      redirectSignOut: 'https://internal.beta.schedule-finder.cs.amazon.dev',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  prod: {
    region: 'us-east-1',
    poolId: 'us-east-1_P6YWk31la',
    clientId: 'mc4ep6d2q94ahg6f82cbpi3pu',
    oauth: {
      domain: 'schedule-manager.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://internal.na.schedule-finder.cs.amazon.dev',
      redirectSignOut: 'https://internal.na.schedule-finder.cs.amazon.dev',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
export const UserPoolConfig = getEnvConfig(userPoolConfig);
