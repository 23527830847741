import { ADD_PENDING_CANDIDATES } from '../actions/types';

const pendingCandidates = (state = defaultPendingCandidatesState, action) => {
  switch (action.type) {
    case ADD_PENDING_CANDIDATES:
      return action.payload;
    default:
      return state;
  }
};

const defaultPendingCandidatesState = [];

export default pendingCandidates;
