import Amplify from 'aws-amplify';
import { UserPoolConfig } from '../../configurations/userPool';
import { ApiConfig } from '../../configurations/apis';

Amplify.configure({
  Auth: {
    region: UserPoolConfig.region,
    userPoolId: UserPoolConfig.poolId,
    userPoolWebClientId: UserPoolConfig.clientId,
    oauth: UserPoolConfig.oauth,
  },
  API: ApiConfig,
});
