import {
  UPDATE_CANDIDATES,
  UPDATE_CANDIDATE,
  ADD_CANDIDATES,
} from '../actions/types';

const candidates = (state = defaultCandidatesState, action) => {
  switch (action.type) {
    case ADD_CANDIDATES:
      return action.payload;
    case UPDATE_CANDIDATES:
      return [...action.payload, ...state];
    case UPDATE_CANDIDATE:
      return state;
    default:
      return state;
  }
};

const defaultCandidatesState = [];

export default candidates;
