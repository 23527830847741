import { combineReducers } from 'redux';
import schedules from './schedules';
import selectedSchedules from './selectedSchedules';
import candidates from './candidates';
import pendingCandidates from './pendingCandidates';
import loading from './loading';
import bannerMessages from './bannerMessages';
import admin from './admin';
/**
 * Returns the combined reducer.
 * @param state
 * @param action
 */

export default combineReducers({
  schedules,
  selectedSchedules,
  candidates,
  pendingCandidates,
  loading,
  bannerMessages,
  admin,
});
