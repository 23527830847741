import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';

const Authenticated = (props) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
          case 'cognitoHostedUI':
            getUser().then(userData => setUser(userData));
            break;
          case 'signOut':
            setUser(null);
            break;
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
            break;
        }
      });

      getUser().then(userData => setUser(userData));
    }, []);

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then(userData => userData)
            .catch(() => {});
    }

    if (user) {
        return props.children;
    }

    return (
        <Column
        alignmentVertical="center"
        backgroundColor="primary"
        spacingInset="xlarge"
        className="welcomeSection"
        >
            <Row alignmentHorizontal="center" spacingInset="none none large none">
                <Button onClick={() => Auth.federatedSignIn()}>Federated Sign In</Button>
            </Row>
        </Column>
    );
};

export default Authenticated;